import React from 'react';
import { inrToUsd, toLocalePrice } from '../../../utils/StringUtil';

const DataCard = ({ value, label, showCurrency = false, currencySymbol, currency, inrAgainstUsd }) => {
  const isLoading = value === undefined || value === null;

  return (
    <div className="col">
      <div className="card bg-white p-4 rounded-lg shadow-md flex-1">
        <div className="card-body">
          {isLoading ? (
            <div>
              {/* Skeleton loader */}
              <div
                style={{
                  backgroundColor: '#e0e0e0',
                  height: '24px',
                  width: '75%',
                  borderRadius: '4px',
                  marginBottom: '12px',
                }}
              ></div>
              <div
                style={{
                  backgroundColor: '#f0f0f0',
                  height: '16px',
                  width: '50%',
                  borderRadius: '4px',
                }}
              ></div>
            </div>
          ) : (
            <>
              <h2 className="card-title text-primary mb-0">
                {showCurrency
                  ? (
                    currency === 'INR'
                      ? `${currencySymbol}${toLocalePrice(value)}`
                      : `${currencySymbol}${toLocalePrice(inrToUsd(value, inrAgainstUsd, currency), 'en-US', currency)}`
                  )
                  : `${value}`}
              </h2>
              <p className="card-text text-muted small mt-2">{label}</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataCard;
