import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Switch, FormControlLabel, Box, Divider } from '@mui/material';
import Layout from '../../components/layout/Layout';
import Home from './home';
import Investor from './investor';
import Revenue from './revenue';
import Investment from './investment';
import Aum from './aum';
import { apiGetDshbordCardData, apiGetDshbordGraphData } from '../../utils/api/api';
import { useSnackbar } from 'notistack';
import { SNACKBAR_ERROR } from '../../utils/constants/constants';
import { convertToUsd } from '../../utils/StringUtil';

const Dashboard = () => {

    const [index, setIndex] = useState(0);
    const [currency, setCurrency] = useState('INR');
    const [cardData, setCardData] = useState({});
    const [numInvList,setNumInvList] = useState([]);
    const [activeInvList, setActiveInvList] = useState([]);
    const [uniqueInvList, setUniqueInvList] = useState([]);
    const [revenueList, setRevenueList] = useState([]);
    const [investeeCompanyList, setInvesteeCompanyList] =  useState([]);
    const [deploymentList, setDeployementList] = useState([]);
    const [aumCommittedList, setAumCommittedList] = useState([]);
    const [aumInvestedList, setAumInvestedList] = useState([]);
    const [inrAgainstUsd, setInrAgainstUsd] = useState(0.00);
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (event, newValue) => {
        setIndex(newValue);
    };

    const handleCurrencyChange = (event) => {
        setCurrency(event.target.checked ? 'USD' : 'INR');
    };

    const getDashboardCardData = () => {
        apiGetDshbordCardData()
            .then((res) => {
                setCardData(res.dashboardData);
                setInrAgainstUsd(res.inrAgainstUsd);
            })
            .catch((e) => {
                enqueueSnackbar(e, SNACKBAR_ERROR);
            });
    };

    const getDashboardGraphData = () => {
        apiGetDshbordGraphData()
            .then((res) => {
                setNumInvList(res.numInvList);
                setActiveInvList(res.activeInvList);
                setUniqueInvList(res.uniqueInvList);
                setRevenueList(res.revenueList);
                setInvesteeCompanyList(res.closedSchemeList)
                setDeployementList(res.deploymentList);
                setAumCommittedList(res.aumCommittedList);
                setAumInvestedList(res.aumInvestedList);
            })
            .catch((e) => {
                enqueueSnackbar(e, SNACKBAR_ERROR);
            });
    };

    useEffect(() => {
        getDashboardCardData();
        getDashboardGraphData();
    }, []);

    return (
        <Layout title="Dashboard">
            <Box display="flex" alignItems="center" >
                <Tabs value={index} onChange={handleChange} indicatorColor="primary" textColor="primary">
                    <Tab label="HOME" />
                    <Tab label="INVESTOR" />
                    <Tab label="REVENUE" />
                    <Tab label="INVESTMENT" />
                    <Tab label="AUM" />
                </Tabs>

                <FormControlLabel
                    control={
                        <Switch
                            checked={currency === 'USD'}
                            onChange={handleCurrencyChange}
                            name="currencyToggle"
                            color="primary"
                        />
                    }
                    label={currency === 'USD' ? 'USD' : 'INR'}
                    style={{ marginLeft: 'auto' }}
                />
            </Box>

            <Divider style={{ height: '1px', backgroundColor: '#000' }} />

            <div>
                {index === 0 && 
                <Home 
                    currency={currency} 
                    data={cardData} 
                    inrAgainstUsd = {inrAgainstUsd}
                />}
                {index === 1 && 
                <Investor 
                    currency={currency} 
                    numInvList = {numInvList} 
                    uniqueInvList = {uniqueInvList} 
                    activeInvList = {activeInvList}
                    inrAgainstUsd = {inrAgainstUsd}
                />}
                {index === 2 && 
                <Revenue
                currency={currency}
                revenueList={revenueList}
                inrAgainstUsd={inrAgainstUsd}
              />
              
                }
                {index === 3 && 
                <Investment 
                    currency={currency} 
                    investeeCompanyList = {investeeCompanyList} 
                    deploymentList = {deploymentList} 
                    inrAgainstUsd = {inrAgainstUsd}
                />}
                {index === 4 && 
                <Aum 
                    currency={currency} 
                    aumCommittedList = {aumCommittedList} 
                    aumInvestedList = {aumInvestedList}
                    inrAgainstUsd = {inrAgainstUsd}
                />}
            </div>

        </Layout>
    );
};

export default Dashboard;
