import React from 'react';
import { Line, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, ComposedChart } from 'recharts';
import ChartCard from '../../components/dashboard/ChartCard';
import { convertNotation, convertToUsd } from '../../utils/StringUtil';

const Aum = ({ currency: parentCurrency, aumCommittedList, aumInvestedList, inrAgainstUsd }) => {

  const formatAumCommittedList = (list) => {
    if (parentCurrency === 'USD') {
      return list.map((item) => ({
        ...item,
        cumulativeMcaCommitment: convertToUsd(item.cumulativeMcaCommitment, inrAgainstUsd),
        momMcaCommitment: convertToUsd(item.momMcaCommitment, inrAgainstUsd),
      }));
    }
    return list;
  };

  const formattedAumCommittedList = formatAumCommittedList(aumCommittedList);

  const formatAumInvestedList = (list) => {
    if (parentCurrency === 'USD') {
      return list.map((item) => ({
        ...item,
        cumulativeInvestment: convertToUsd(item.cumulativeInvestment, inrAgainstUsd),
        momInvestment: convertToUsd(item.momInvestment, inrAgainstUsd),
      }));
    }
    return list;
  };

  const formattedAumInvestedList = formatAumInvestedList(aumInvestedList);

  return (
    <div className="container-fluid py-4">
      <div className="row">
        {/*AUM committed*/}
        <div className="col-md-6">
          <ChartCard title="AUM Committed"
            chartInfo={[
              { name: "Total MCA Commitments", color: "#dda0dd" },
              { name: "MOM MCA Commitments", color: "#602f6b" },
            ]}
          >
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={formattedAumCommittedList}
              >
                <XAxis dataKey="month" tick={{ fontSize: 10, fontWeight: 'bold' }} />
                <YAxis
                  yAxisId="left"
                  label={{ value: 'Cumulative Commitment', angle: -90, fontWeight: 'bold', position: 'outsideCenter', fill: "#dda0dd", dx: -15 }}
                  stroke="#dda0dd"
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                />
                {/* Right Y-Axis for Count */}
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{
                    value: 'MOM Commitment',
                    fontWeight: 'bold',
                    angle: -90,
                    position: 'outsideRight',
                    fill: "#602f6b",
                    dx: 15,
                  }}
                  stroke="#602f6b"
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                />
                <Tooltip />
                <Bar yAxisId="left" dataKey="cumulativeMcaCommitment" barSize={40} fill="#dda0dd" name="Cumulative Commitment" />
                <Line yAxisId="right" type="monotone" dataKey="momMcaCommitment" fill="#602f6b" stroke="#8e4585" name='MOM Commitment' strokeWidth={1} dot={{ r: 2 }} />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartCard>
        </div>
        {/*AUM invested*/}
        <div className="col-md-6">
          <ChartCard title="AUM Invested"
            chartInfo={[
              { name: "Total Fund Received", color: "#cb99c9" },
              { name: "MOM Fund Received", color: "#8e4585" },
            ]}
          >
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart
                data={formattedAumInvestedList}
              >
                <XAxis dataKey="month" tick={{ fontSize: 10, fontWeight: 'bold' }} />
                <YAxis
                  yAxisId="left"
                  label={{ value: 'Cumulative Investment', angle: -90, fontWeight: 'bold', position: 'outsideCenter', fill: "#cb99c9", dx: -29 }}
                  stroke="#cb99c9"
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                />
                {/* Right Y-Axis for Count */}
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{
                    value: 'MOM Investment',
                    fontWeight: 'bold',
                    angle: -90,
                    position: 'outsideRight',
                    fill: "#8e4585",
                    dx: 29,
                  }}
                  stroke="#8e4585"
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                />
                <Tooltip />
                <Bar yAxisId="left" dataKey="cumulativeInvestment" barSize={40} fill="#cb99c9" name="Cumulative Investments" />
                <Line yAxisId="right" type="monotone" dataKey="momInvestment" fill="#8e4585" stroke="#8e4585" name='MOM Investments' strokeWidth={1} dot={{ r: 2 }} />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartCard>
        </div>
      </div>
    </div>
  );
};

export default Aum;
