import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import ChartCard from '../../components/dashboard/ChartCard';
import { convertNotation, convertToUsd } from '../../utils/StringUtil';

const Revenue = ({ currency: parentCurrency, revenueList, inrAgainstUsd }) => {

  // Map Revenue data depending on the USD conversion
  const formatList = (list) => {
    if (parentCurrency === 'USD') {
      return list.map((item) => ({
        ...item,
        totalRevenue: convertToUsd(item.totalRevenue, inrAgainstUsd),
      }));
    }
    return list;
  };

  const formattedList = formatList(revenueList);

  return (
    <div className="container-fluid py-4">
      <div className="row">
        <div className="col-md-6">
          <ChartCard
            title="Total Revenue Generated"
            chartInfo={[{ name: "MOM Revenue", color: "#dda0dd" }]}
          >
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={formattedList}>
                <XAxis
                  dataKey="month"
                  tick={{ fontSize: 10, fontWeight: 'bold' }}
                  strokeWidth={2}
                />
                <YAxis
                  tickFormatter={(value) => convertNotation(value, inrAgainstUsd, parentCurrency)}
                  tick={{ fontSize: 12, fontWeight: 'bold' }}
                  strokeWidth={2}
                />
                <Tooltip />
                <Bar
                  dataKey="totalRevenue"
                  fill="#dda0dd"
                  name="MOM Total Revenue"
                />
              </BarChart>
            </ResponsiveContainer>
          </ChartCard>
        </div>
      </div>
    </div>
  );
};

export default Revenue;
