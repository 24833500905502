const ChartCard = ({ title, children, chartInfo }) => (
  <div className="card mb-4 p-2">
    <div className="d-flex justify-content-between align-items-center px-1 pt-2">
  <h5 className="card-title text-muted fw-bold fs-6">
    {title}
  </h5>
  {chartInfo && (
    <div
      className="d-flex flex-column"
      style={{
        height: "50px",
        overflowY: "auto",
       justifyContent: "center",
      }}
    >
      {chartInfo.map((info, index) => (
        <div key={index} className="d-flex align-items-center mb-1">
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: info.color,
              marginRight: 5,
            }}
          ></div>
          <span style={{ fontSize: 12 }}>{info.name}</span>
        </div>
      ))}
    </div>
  )}
</div>



    <div className="pe-4 pt-2 pb-2">
      {children}
    </div>
  </div>
);

export default ChartCard;