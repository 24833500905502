import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import DashboardIcon from '@mui/icons-material/Dashboard';

import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import AutoGraphRoundedIcon from "@mui/icons-material/AutoGraphRounded";
import SegmentRoundedIcon from "@mui/icons-material/SegmentRounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import ContactSupportRoundedIcon from "@mui/icons-material/ContactSupportRounded";
import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import HandshakeIcon from "@mui/icons-material/Handshake";
import DetailsIcon from '@mui/icons-material/Details';
import TableViewIcon from '@mui/icons-material/TableView';
import EmailIcon from '@mui/icons-material/Email';
const SideBar = () => {
  const location = useLocation();
  const userType = process.env.REACT_APP_USER_TYPE;
  // console.log(location.pathname)
  return (
    <div
      id="sidebarContainer"
      className="vh-100 bg-primary"
      style={{ position: "sticky" }}
    >
      <img
        className="px-5 py-4"
        src="/logo.png"
        alt="Logo"
        style={{ width: "100%" }}
      />
      <div className="sidenav-group mt-3 d-flex align-items-start">
        <div className="nav flex-column nav-pills ms-2 w-100">
          {/* <Link
            to={{ pathname: "/" }}
            className={
              "nav-link sidenav-item fs-x-large-regular text-white text-start " +
              (location.pathname === "/"
                ? "active text-dark fs-x-large-semibold"
                : "fs-x-large-regular")
            }
            type="button"
            role="tab"
            aria-selected="true"
          >
            <div className="d-flex flex-row h-100 my-auto ">
              <DashboardCustomizeOutlinedIcon />
              <p className="sidenav-item-text my-auto  ms-2">Dashboard</p>
            </div>
          </Link> */}


          <Link
            to={{ pathname: userType=== 'ADMIN' ? "/admin/dashboard" : "/partner/dashboard" }}
            className={
              "nav-link sidenav-item text-white fs-large-regular text-start " +
              (userType === "ADMIN"
                ? location.pathname === "/admin/dashboard" 
                  ? "active text-dark fs-large-semibold"
                  : "fs-large-regular"
                : location.pathname === "/partner/dashboard" 
                ? "active text-dark fs-large-semibold"
                : "fs-large-regular")
            }
            type="button"
            role="tab"
            aria-selected="false"
          >
            <div className="d-flex flex-row h-100 my-auto">
              <DashboardIcon />
              <span className="sidenav-item-text  ms-2">Dashboard</span>
            </div>
          </Link>


          <Link
            to={{ pathname: userType=== 'ADMIN' ? "/admin/scheme" : "/partner/scheme" }}
            className={
              "nav-link sidenav-item text-white fs-large-regular text-start " +
              (userType === "ADMIN"
                ? location.pathname === "/admin/scheme" ||
                  location.pathname === "/admin/scheme/investor" ||
                  location.pathname === "/admin/scheme/description"
                  ? "active text-dark fs-large-semibold"
                  : "fs-large-regular"
                : location.pathname === "/partner/scheme" ||
                  location.pathname === "/partner/scheme/investor" ||
                  location.pathname === "/partner/scheme/description"
                ? "active text-dark fs-large-semibold"
                : "fs-large-regular")
            }
            type="button"
            role="tab"
            aria-selected="false"
          >
            <div className="d-flex flex-row h-100 my-auto">
              <FormatListBulletedRoundedIcon />
              <span className="sidenav-item-text  ms-2">Scheme</span>
            </div>
          </Link>

          <Link
            to={{ pathname: userType=== 'ADMIN' ? "/admin/fund" : "/partner/fund" }}
            className={
              "nav-link sidenav-item text-white fs-large-regular text-start " +
              (userType === 'ADMIN'
              ?
                location.pathname === "/admin/fund"
                ? "active text-dark fs-large-semibold"
                : "fs-large-regular"
               :location.pathname === "/partner/fund"
               ? "active text-dark fs-large-semibold"
               : "fs-large-regular"
              )
            }
            type="button"
            role="tab"
            aria-selected="false"
          >
            <div className="d-flex flex-row h-100 my-auto">
              <AccountBalanceWalletRoundedIcon />
              <span className="sidenav-item-text ms-2">Fund Master</span>
            </div>
          </Link>


          {userType === "ADMIN" && (
            <Link
              to={{ pathname: "/admin/unit-transfer" }}
              className={
                "nav-link sidenav-item text-white fs-large-regular text-start " +
                (location.pathname === "/admin/unit-transfer"
                  ? "active text-dark fs-large-semibold"
                  : "fs-large-regular")
              }
              type="button"
              role="tab"
              aria-selected="false"
            >
              <div className="d-flex flex-row h-100 my-auto">
                <SwapHorizRoundedIcon />
                <span className="sidenav-item-text ms-2">Unit Transfer</span>
              </div>
            </Link>
          )}
          <Link
            to={{ pathname: userType === 'ADMIN' ? "/admin/investors" : "/partner/investors"}}
            className={
              "nav-link sidenav-item text-white fs-large-regular  text-start " +
              (userType === 'ADMIN' 
              ?
                location.pathname === "/admin/investors"
                ? "active text-dark fs-large-semibold"
                : "fs-large-regular"
              :
              location.pathname === "/partner/investors"
                ? "active text-dark fs-large-semibold"
                : "fs-large-regular"
                )
            }
            type="button"
            role="tab"
            aria-selected="false"
          >
            <div className="d-flex flex-row h-100 my-auto  ">
              <GroupsRoundedIcon />
              <span className="sidenav-item-text  ms-2">Investors</span>
            </div>
          </Link>
          {userType === "ADMIN" && (
            <Link
              to={{ pathname: "/admin/partners" }}
              className={
                "nav-link sidenav-item text-white fs-large-regular  text-start " +
                (location.pathname === "/admin/partners"
                  ? "active text-dark fs-large-semibold"
                  : "fs-large-regular")
              }
              type="button"
              role="tab"
              aria-selected="false"
            >
              <div className="d-flex flex-row h-100 my-auto  ">
                <HandshakeIcon />
                <span className="sidenav-item-text  ms-2">Partners</span>
              </div>
            </Link>
          )}
           {userType === "ADMIN" && (
            <Link
              to={{ pathname: "/admin/demat-master" }}
              className={
                "nav-link sidenav-item text-white fs-large-regular  text-start " +
                (location.pathname === "/admin/demat-master"
                  ? "active text-dark fs-large-semibold"
                  : "fs-large-regular")
              }
              type="button"
              role="tab"
              aria-selected="false"
            >
              <div className="d-flex flex-row h-100 my-auto  ">
                <TableViewIcon />
                <span className="sidenav-item-text  ms-2">Demat</span>
              </div>
            </Link>
          )}
          {userType === "ADMIN" && (
            <Link
              to={{ pathname: "/admin/portfolio" }}
              className={
                "nav-link sidenav-item text-white fs-large-regular text-start " +
                (location.pathname === "/admin/portfolio"
                  ? "active text-dark fs-large-semibold"
                  : "fs-large-regular")
              }
              type="button"
              role="tab"
              aria-selected="false"
            >
              <div className="d-flex flex-row h-100 my-auto">
                <AutoGraphRoundedIcon />
                <span className="sidenav-item-text  ms-2">
                  Sprint Portfolio
                </span>
              </div>
            </Link>
          )}
          {userType === "ADMIN" && (
            <Link
              to={{ pathname: "/admin/class" }}
              className={
                "nav-link sidenav-item text-white fs-large-regular  text-start " +
                (location.pathname === "/admin/class"
                  ? "active text-dark fs-large-semibold"
                  : "fs-large-regular")
              }
              type="button"
              role="tab"
              aria-selected="false"
            >
              <div className="d-flex flex-row h-100 my-auto  ">
                <SegmentRoundedIcon />
                <span className="sidenav-item-text  ms-2">Class</span>
              </div>
            </Link>
          )}
          {userType === "ADMIN" && (
            <Link
              to={{ pathname: "/admin/company" }}
              className={
                "nav-link sidenav-item text-white fs-large-regular  text-start " +
                (location.pathname === "/admin/company"
                  ? "active text-dark fs-large-semibold"
                  : "fs-large-regular")
              }
              type="button"
              role="tab"
              aria-selected="false"
            >
              <div className="d-flex flex-row h-100 my-auto  ">
                <BusinessRoundedIcon />
                <span className="sidenav-item-text  ms-2">Company</span>
              </div>
            </Link>
          )}
          {userType === "ADMIN" && (
            <Link
              to={{ pathname: "/admin/company-updates" }}
              className={
                "nav-link sidenav-item text-white fs-large-regular  text-start " +
                (location.pathname === "/admin/company-updates"
                  ? "active text-dark fs-large-semibold"
                  : "fs-large-regular")
              }
              type="button"
              role="tab"
              aria-selected="false"
            >
              <div className="d-flex flex-row h-100 my-auto  ">
                <UpdateRoundedIcon />
                <span className="sidenav-item-text  ms-2">Com. Updates</span>
              </div>
            </Link>
          )}
          {userType === "ADMIN" && (
            <Link
              to={{ pathname: "/admin/massInvestorMailer" }}
              className={
                "nav-link sidenav-item text-white fs-large-regular  text-start " +
                (location.pathname === "/admin/massInvestorMailer"
                  ? "active text-dark fs-large-semibold"
                  : "fs-large-regular")
              }
              type="button"
              role="tab"
              aria-selected="false"
            >
              <div className="d-flex flex-row h-100 my-auto  ">
                <EmailIcon />
                <span className="sidenav-item-text  ms-2">Mass Investor Mailer</span>
              </div>
            </Link>
          )}
          {userType === "ADMIN" && (
            <Link
              to={{ pathname: "/admin/queries" }}
              className={
                "nav-link sidenav-item text-white fs-large-regular  text-start " +
                (location.pathname === "/admin/queries"
                  ? "active text-dark fs-large-semibold"
                  : "fs-large-regular")
              }
              type="button"
              role="tab"
              aria-selected="false"
            >
              <div className="d-flex flex-row h-100 my-auto  ">
                <ContactSupportRoundedIcon />
                <span className="sidenav-item-text  ms-2">Queries</span>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
export default SideBar;
