import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import styles from "../sip/Sip.module.css";
import { companyEdit, saveCompany, uploadImage } from "../../utils/api/api";
import { BASE_URL, SNACKBAR_ERROR, SNACKBAR_SUCCESS, SNACKBAR_WARNING } from "../../utils/constants/constants";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from 'notistack';
import { isValidPAN } from "../../utils/Validator";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  maxHeight: '80vh',
  bgcolor: "background.paper",
  boxShadow: 24,
  overflow: "auto",
  padding: '0px 20px',
};

const CompanyModal = ({ open, onClose, isEdit, company, handleSave }) => {
  const [companyForm, setCompanyForm] = useState(company);
  const [titleError, setTitleError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [investorPanError, setInvestorPanError] = useState(false);
  const [actionLoader, setActionLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setTitleError(false);  
    
    if (name === 'panNumber') {
      if (value.trim() === '') {
        setInvestorPanError(false); 
      } else if (!isValidPAN(value)) {
        setInvestorPanError(true); 
      } else {
        setInvestorPanError(false);
      }
    }
  
    setCompanyForm({
      ...companyForm,
      [name]: value.trim(),
    });
  };
  

  const handleClose = () => {
    setInvestorPanError(false);
    setTitleError(false)
    onClose()
  }

  const onSubmit = () => {

    if (!companyForm.title || companyForm.title.trim() === '') {
      enqueueSnackbar("Please fill in the Company Title", SNACKBAR_WARNING);
      return;
    }

    // if (!companyForm.description || companyForm.description.trim() === '') {
    //   enqueueSnackbar("Please fill in the Company Description", SNACKBAR_WARNING);
    //   return;
    // }

    // if (!companyForm.legalNameOfEntity || companyForm.legalNameOfEntity.trim() === '') {
    //   enqueueSnackbar("Please fill in the Legal Name of Entity", SNACKBAR_WARNING);
    //   return;
    // }

    // if (!companyForm.panNumber || companyForm.panNumber.trim() === '') {
    //   enqueueSnackbar("Please fill in the Pan Number", SNACKBAR_WARNING);
    //   return;
    // }

    setIsLoading(true)
   
    if (isEdit) {
      companyEdit(companyForm)
        .then((res) => {
          setIsLoading(false)
          enqueueSnackbar(res, SNACKBAR_SUCCESS)
          handleSave()
        })
        .catch((e) => {
          enqueueSnackbar(e,SNACKBAR_ERROR)
          setIsLoading(false)
        })
    } else {
      saveCompany(companyForm)
        .then((res) => {
          setIsLoading(false)
          enqueueSnackbar(res,SNACKBAR_SUCCESS)
          handleSave()
        })
        .catch((e) => {
          enqueueSnackbar(e,SNACKBAR_ERROR)
          setIsLoading(false)
        })
    }
  
  }

  const addImage = (e) => {
    uploadImage(e.target.files[0])
      .then((res) => {
        setCompanyForm({
          ...companyForm,
          logoUrl: res.data.path,
        });
      })
      .catch((e) => {
        enqueueSnackbar(e,SNACKBAR_ERROR)
      })
  };

  useEffect(() => {
    if (isEdit) {
      setCompanyForm(company);
    } else {
      setCompanyForm({});
    }
  }, [company]);

  const openExlporer = () => {
    document.getElementById('input-file').click()
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <div className="modal-header">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {isEdit ? 'Edit Company' : 'Add Company'}
          </Typography>
        </div>
        <Stack sx={{ marginBottom: "1rem", marginTop: '1rem' }} direction="row" spacing={2}>
          <TextField
            sx={{ flex: 1 }}
            id="outlined-basic"
            name="title"
            error={titleError}
            helperText={titleError ? "This field is Required" : ""}
            label="Company Title"
            variant="outlined"
            defaultValue={company ? company.title : ""}
            fullWidth
            onChange={(e) => handleChange(e)}
          />
        </Stack>
        <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
          <TextField
            minRows={3}
            multiline
            defaultValue={company ? company.description : ""}
            name="description"
            onChange={handleChange}
            label="Company description"
            placeholder="Company description"
            style={{ width: "100%" }}
          />
        </Stack>
        <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
          <TextField
            sx={{ flex: 1 }}
            id="outlined-basic"
            name="legalNameOfEntity"
            label="Legal Name of Entity"
            variant="outlined"
            defaultValue={company ? company.legalNameOfEntity : ""}
            fullWidth
            onChange={(e) => handleChange(e)}
          />
        </Stack>
        <Stack sx={{ marginBottom: "1rem" }} direction="row" spacing={2}>
          <TextField
            sx={{ flex: 1 }}
            id="outlined-basic"
            name="panNumber"
            label="Pan Number"
            variant="outlined"
            defaultValue={company ? company.panNumber : ""}
            fullWidth
            onChange={(e) => handleChange(e)}
            error={investorPanError}
            helperText={investorPanError ? 'Please enter a valid PAN number' : ''}
          // InputProps={{
          //   endAdornment: (
          //     <Button
          //       variant="outlined"
          //       color="success"
          //       sx={{ textTransform: 'none' }}
          //       startIcon={<CheckCircleIcon />}
          //       disabled={actionLoader || !isValidPAN(investorPan)}
          //     >
          //       <span className="fs-med-medium">Verify</span>
          //     </Button>
          //   ),
          // }}
          />
        </Stack>
        <input
          className="d-none"
          id="input-file"
          type="file"
          onClick={(e) => {
            e.target.value = null;
          }}
          onChange={(e) => {
            addImage(e);
          }}
        />
        <p className="section-label mt-4 mb-0">
          Logo
          </p>
        <Stack direction="row" sx={{ mt: 2 }}>
          <img className="img-preview" onClick={() => openExlporer()} src={companyForm?.logoUrl ? `${BASE_URL}/${companyForm?.logoUrl}` : '/no-image.jpg'} />
        </Stack>

        <div className="row mx-0 justify-content-end w-100 mt-3 modal-footer bg-white py-4">
          <button className="btn w-fit px-4 me-1" onClick={() => handleClose()}>
            Cancel
          </button>
          {
            isLoading ? 
            (
              <div 
                className="btn w-fit px-4"
              >
                <CircularProgress />
               </div>
            ) :
            (
              <button
                className="btn btn-primary w-fit px-4"
                type="submit"
                onClick={() => onSubmit()}
              >
                Save
              </button>
            )
        }
        </div>
      </Box>
    </Modal>
  );
}

export default CompanyModal;