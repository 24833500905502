import { Button, Link, TextField } from '@mui/material';
import React, { useState } from 'react';
import { apiEnableResendOtp, verifyOtp } from '../../utils/api/api';
import { SNACKBAR_ERROR, SNACKBAR_SUCCESS } from '../../utils/constants/constants';
import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from "react-router";
import { getAdminTransactionToken, setSubAdminType } from '../../utils/LocalStorageUtils';

function OtpPage() {
    const history = useHistory();
    const location = useLocation();
    const { transactionToken, email } = location.state || '';
    const otpLength = 4;
    const inputElements = [];
    const [otp, setOtp] = useState(Array(otpLength).fill(''));
    const [newOtp, setNewOtp] = useState('');
    const { enqueueSnackbar } = useSnackbar();

    const handleInputChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        setNewOtp(newOtp.join(''));
    
        if (index < otpLength - 1 && value !== '') {
            inputElements[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
            inputElements[index - 1].current.focus();
        }
    };

    const onSubmit = () => {
        const reqBody = {
            "transactionToken": getAdminTransactionToken(),
            "otp": newOtp,
            "email": email
        }
        verifyOtp(reqBody)
            .then((res) => {
                if(res.status){
                    setSubAdminType(res?.data?.user?.userType);
                    history.push("/admin/dashboard");
                }
            })
            .catch((e) => {
                enqueueSnackbar(e, SNACKBAR_ERROR);
            });
    }

    const onResendOtp = () => {
        const reqBody = {
            "transactionToken": getAdminTransactionToken(),
        }
        apiEnableResendOtp(reqBody)
            .then((res) => {
                enqueueSnackbar("OTP Sent Successfully", SNACKBAR_SUCCESS);
            })
            .catch((e) => {
                enqueueSnackbar(e, SNACKBAR_ERROR);
            })
    }

  return (
    <div className="login-wrapper row mx-0">
        <div className="bg-primary col-5 m-4 rounded d-flex align-items-center justify-content-center">
            <img src="/logo.png" alt="logo" />
        </div>
        <div className="col-4 d-flex flex-column p-5 align-items-center justify-content-center">
            <h2 className="w-100 text-start text-primary">OTP Verification</h2>
            <p className="w-100 text-start fs-med-semibold">We will send you OTP via SMS</p>

            <div className="mt-4 w-100">
                <div className="d-flex gap-2">
                    {otp.map((data, key) => (
                        <TextField
                            key={key}
                            type="text"
                            value={data}
                            variant="standard"
                            onChange={(e) => handleInputChange(key, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(e, key)}
                            inputRef={(input) => inputElements[key] = input}
                            inputProps={{
                                maxLength: 1,
                                className: 'fs-large-semibold text-center p-3'
                            }}
                        />
                    ))}
                </div>
                <Button
                    variant="contained"
                    color="secondary"
                    className="mt-4 w-100"
                    onClick={onSubmit}
                    disabled={newOtp.trim().length === otpLength ? false : true}>
                    Verify
                </Button>

                <div className="d-flex gap-2 align-items-center justify-content-center mt-3">
                    <span className="fs-med-medium">Didn't receive OTP?</span>
                    <span className="fs-med-medium text-primary" style={{cursor:'pointer'}} onClick={onResendOtp}>
                        Resend
                    </span>
                </div>
                {/* <Button
                    color="secondary"
                    className="mt-4 w-100"
                    onClick={onResendOtp}>
                        Resend OTP
                </Button> */}
            </div>
        </div>
    </div>
  );
}

export default OtpPage;